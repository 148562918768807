<template>
  <div class="info accent">
    <b-container>
      <b-row>
        <b-col cols="12" lg="6">
          <div class="werk-container">
            <div class="werk-text">
              <h2 id="werkwijze">Werkwijze</h2>
              Tijdens een intakegesprek neem ik de tijd voor een uitgebreide inventarisatie van jouw of
              jullie huidige situatie. Hulptrajecten zijn altijd op maat en in overleg, afgestemd op jouw eigen hulpvraag
              en behoefte. Er komt een duidelijk startverslag met doelen en de gewenste eindsituatie en met de afspraken
              die we met elkaar hierover hebben gemaakt. Deze worden tussentijds, maar in elk geval aan het eind van een
              afgesproken traject gezamenlijk geëvalueerd.
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <img alt="Bloem op Stenen" class="img-fluid" :src="require('../../assets/bloem-op-steen.jpg')">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Werkwijze"
}
</script>

<style lang="scss" scoped>
.werk-container {
  display:table;
  height:100%;
}

.werk-text {
  height:100%;
  vertical-align: middle;
  display:table-cell;
}
</style>