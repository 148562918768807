<template>
  <div>
    <b-img
      class="img-header"
      :src="require('@/assets/SpringTalentbegeleiding_Logo_rgb.png')"
      fluid-grow
    ></b-img>
  </div>
</template>

<script>
export default {
  name: "Heading"
}
</script>

<style scoped>
  .img-header {
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("../../assets/dunes.jpg");
    background-size: cover;
  }

  @media screen and (max-width: 800px) {
    .img-header {
      padding-top: 40px;
    }
  }
</style>