<template>
  <div class="info accent">
    <b-container>
      <b-row>
        <b-col>
          <h1 id="home">Spring Talentbegeleiding</h1>
          <p>
            Spring Talentbegeleiding is opgericht in 2017 door mij, Cilleke van Gijzen-Markslag.
            Ik ben geregistreerd jeugd- en gezinshulpverlener. Vanaf 2015 heb ik mij gespecialiseerd op het gebied van
            talentontwikkeling en hoogbegaafdheid in al haar verscheidenheid. Vanuit mijn praktijk Spring
            Talentbegeleiding help ik ouders en kinderen bij het ontdekken van wie zij zijn en hoe zij hun anders-denken
            en intens voelen kunnen gaan herkennen als kracht in plaats van belemmering. Dit doe ik onder andere door
            middel van coachende gesprekken, samen oefenen in begeleiding en door het geven van psycho-educatie. Ook bij
            hulpvragen rondom kenmerken van hoogsensitiviteit, beelddenken, vermoedens van ADD of een ander 'label' ga
            ik graag met je aan de slag. Ik werk niet zozeer vanuit labels maar vanuit een brede, nieuwsgierige blik
            naar wie jij bent en wat jij nodig hebt om zelf weer te gaan groeien.
          </p>
        </b-col>
        <b-col xs="12" lg="7">
          <b-img style="padding-top:8px;" :src="require('@/assets/pexels-jessica-ticozzelli-5985670.jpg')" fluid-grow></b-img>
        </b-col>
      </b-row>
      <b-row style="padding-top: 40px;">
        <b-col lg="6" cols="12">
          <img class="img-fluid" :src="require('../../assets/bloesem-in-hand.jpg')">
        </b-col>
        <b-col lg="6" cols="12">
          <h3>Talent (weer) tot bloei laten komen</h3>
          Kinderen ontwikkelen zich op hun eigen manier en in hun eigen tempo. Sommige kinderen kunnen daarbij
          (tijdelijk) wat meer steun gebruiken, dit kan allerlei oorzaken hebben. Het eerste waar je dit als ouder
          meestal aan merkt is het gedrag van je kind. Klachten als buikpijn, hoofdpijn, somberheid, toenemende angsten,
          afnemende motivatie, niet meer naar school of kinderdagverblijf willen gaan. Ook slaapproblemen, toegenomen
          huilbuien of driftbuien of juist zich terugtrekken zijn voorbeelden van gedrag wat een kind kan laten zien als
          het niet lekker gaat. Als ouder kan het dan een flinke uitdaging zijn om erachter te komen wat je kind nodig
          heeft en hoe je daar op in kunt spelen. Spring Talentbegeleiding is er om te helpen bij het uitpuzzelen van de
          oorzaken van gedrag en bij het vertalen van gedrag naar de behoeftes die daarachter zitten. Samen met het kind
          (of jongere) ga ik op zoek naar krachten, kwaliteiten en belemmeringen en vooral naar wat helpt. Daarbij werk
          ik zoveel mogelijk samen met de omgeving; in de eerste plaats de ouders, maar als het kan ook breder zoals
          dagverblijf of school.
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: "Information"
}
</script>
