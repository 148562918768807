<template>
  <div>
    <div class="info">
      <h2 id="recensies">Recensies</h2>
      <carousel
          class="carousel"
          :per-page="1"
          :mouse-drag="true"
          :navigationEnabled="true"
          :autoplay="true"
          :autoplayTimeout="25000"
          :loop="true"
          :adjustableHeight="true"
      >
        <slide
            :data-index="index" :key="index" v-for="[index, testimonial] of testimonials.entries()">
          <h3>{{testimonial.caption}}</h3>
          <div class="carousel-text" v-html="testimonial.text"></div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: "Recensies",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      testimonials: [
        {
          caption: "Moeder van een dochter van 3",
          text: "Onze dochter zit sinds zij 3 jaar is geworden bij UkIQ, onder begeleiding van Cilleke. Hier krijgt ze " +
              "de extra uitdaging en handvatten die zij nodig heeft om zichzelf te ontwikkelen en te prikkelen. Cilleke" +
              " weet een perfecte balans te vinden tussen aanbod en ruimte voor de inbreng van de kinderen. Er is alle " +
              "aandacht voor hun vragen, ideeën, onderzoeken en ontdekkingen. Het geeft zelfvertrouwen en leert de " +
              "kinderen om te mogen blijven ontwikkelen op hun eigen manier. Wij zijn als ouders erg blij met de hulp " +
              "van Cilleke en de bijeenkomsten van ukIQ. ",
        },
        {
          caption: "Moeder van een zoon van 10 jaar",
          text: "We vonden het een hele stap om hulp in te schakelen voor onze 10 jarige zoon. Maar hebben hier tot de " +
              "dag van vandaag totaal geen spijt van gehad. " +
              "Toen Cilleke voor het eerst kwam was onze 10 jarige zoon erg terug getrokken en in zichzelf maar na loop " +
              "van tijd bloeide hij echt een eind op en durfde hij steeds meer zichzelf te zijn zonder al die boze buien." +
              "Ze kwam niet alleen voor de begeleiding van onze 10 jarige zoon maar ook zeker voor ons als ouders en " +
              "zusje, om extra handvaten te geven. <br><br>" +
              "Aangezien de frustratie zich vooral op school opbouwde, is Cilleke bij verschillende gesprekken " +
              "aangesloten die we met zijn juf en de interne begeleidster voerden. Zodat ook de school beter inzicht " +
              "kreeg hoe hij in elkaar zit en hoe ze hem hierbij het beste kunnen ondersteunen. Hierdoor wordt hij veel " +
              "beter gehoord en gezien op school. " +
              "Dankzij Cilleke is er veel meer rust gekomen in huis en hebben we er alle vertrouwen in dat het helemaal " +
              "goed gaat komen ook voor de toekomst. <br><br>" +
              "We zijn haar echt onwijs dankbaar en hebben een hele leuke en leerzame tijd met elkaar gehad”. ",
        },
        {
          caption: "Frits, vader van 3 kinderen van 5, 8 en 10 jaar",
          text: "Sinds onze oudste zoon (10 jaar) teveel begeleiding nodig had op de BSO vanwege ‘sociaal " +
              "onvoorspelbaar gedrag’, is Cilleke ons gezin komen ondersteunen. We wisten al een tijdje dat onze zoon" +
              " hoogbegaafd is en in de zoektocht naar passend onderwijs kwam daar een schooltrauma bij. Cilleke" +
              " heeft ons mede geholpen om hem zelf de regie te laten pakken om op zijn eigen manier afscheid te " +
              "nemen van de BSO. <br><br>" +
              "Ze kwam wekelijks bij ons thuis en wees ons met haar positieve blik op wat er al wel goed ging. Dit " +
              "heeft ons erg geholpen door een intense periode heen, waarin je als ouder soms vooral ziet wat er niet " +
              "goed gaat. <br><br>" +
              "Cilleke werkt heel fijn vanuit het versterken van de talenten binnen het gezin, waardoor we ons " +
              "gesteund voelden en vanuit een positieve kracht ons gezin sterker is komen te staan. <br><br>" +
              "Na een jaar intensieve begeleiding kan onze zoon beter met zijn emoties omgaan. Dankzij de " +
              "psycho-educatie die Cilleke met hem heeft gedaan, begrijpt hij nu ook beter hoe zijn brein werkt.",
        },
        {
          caption: "Paula",
          text: "“Voor onze dochter (12) waren wij op zoek naar een coach die haar kon helpen het vertrouwen te " +
              "geven dat het helemaal oké is om 'anders' te zijn en dat iedereen zijn eigen talenten heeft. Cilleke " +
              "heeft onze dochter echt geholpen te ontdekken hoe zij in elkaar zit en welke talenten daarbij " +
              "passen. Ook al zit haar spellingsdyslexie en ADD soms in de weg, het levert ook veel mooie dingen op! " +
              "Door samen aandachtig te kijken en aan te sluiten op het niveau van onze dochter (de spelletjes vond " +
              "zij heel erg leuk en ook het samen lezen!) heeft zij (en wij) waardevolle inzichten gekregen en meer " +
              "vertrouwen in haar eigen kunnen.“"
        },
        {
          caption: "Moeder van een hoogbegaafde zoon",
          text: "“Via bekenden kwamen wij bij Cilleke terecht. We waren namelijk meer op zoek naar een coach dan " +
              "een psycholoog voor onze oudste zoon(11 jaar), die hoogbegaafd is gediagnosticeerd. Na een intake " +
              "gaf onze zoon aan zich vooral begrepen te voelen en besloten om het coachingstraject aan te gaan. " +
              "De hulpvraag was goed geformuleerd door Cilleke, wat ook voor ons aan gaf dat ze goed luisterde en " +
              "de goeie dingen er uit haalde. Onze zoon vond de gesprekken heel prettig, ook omdat Cilleke niet " +
              "alleen praatte maar ook ter afwisseling spelletjes deed of bewustwordingsoefeningen. Onze zoon heeft " +
              "erg prettige, ook voor ons, handige tips en tricks gekregen waar wij met zijn allen goed mee verder " +
              "kunnen.”"
        },
        {
          caption: "Leerling groep 8",
          text: "“Het heeft mij geholpen dat jij altijd luisterde naar wat ik vertelde. En als dat dan problemen " +
              "waren dat jij en ik er dan een oplossing voor gingen zoeken.“"
        },
        {
          caption: "Moeder van een meisje (3) met een ontwikkelingsvoorsprong",
          text: "“Cilleke heeft via observaties op het kinderdagverblijf zowel ons als hen handvatten gegeven " +
              "waarmee we samen onze dochter konden helpen. Ze neemt er echt de tijd voor om je te leren kennen. " +
              "Ze weet ook heel goed de vertaalslag te maken van de pedagogische (opvoed)termen naar begrijpelijk " +
              "Nederlands. Haar tips hebben er mede voor gezorgd dat onze dochter weer beter in haar vel zit én de " +
              "leidsters haar (beter) begrijpen.”"
        },
        {
          caption: "Moeder van 2 dochters, 4 en 6 jaar",
          text: "“Cilleke heeft ons net die extra waardevolle tips en informatie gegeven om onze dochters" +
              " beter te begrijpen en begeleiden.  Haar input heeft bovendien geholpen om ook op school met de" +
              " leerkrachten het gesprek goed te kunnen voeren. We horen onze jongste zo vaak enthousiaste dingen" +
              " vertellen aan anderen, waarvan wij weten dat heeft ze bij Cilleke geleerd.  Prachtig toch, hoe" +
              " ze genoten heeft.”"
        },
      ]
    }
  },
  mounted() {
    setInterval(() => {

    }, 25000)
  }
}
</script>


<style lang="scss" scoped>
  #recensies {
    width: 100%;
    text-align: center;
  }

  .carousel-text {
    font-style: italic;
    padding: 5px;
  }

  .carousel {
    margin: 0 auto;
    text-align: center;
  }

  @media screen and (min-width: 992px) {
    .carousel {
      width:80%;
    }
  }

  h3 {
    color: black;
  }
</style>