<template>
  <div id="privacy">
    <h2>Privacyverklaring</h2>
    <p>
      Spring Talentbegeleiding is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze
      privacyverklaring.
    </p>

    <h3>Persoonsgegevens die verwerkt worden</h3>
    <p>
      Spring Talentbegeleiding verwerkt persoonsgegevens van degenen die gebruik maken van onze diensten.
      Persoonsgegevens die verwerkt worden zijn; naam, adresgegevens, telefoonnummer, e-mailadres,
      geboortedatum, BSN wanneer de vergoeding via PGB/ZIN loopt en overige gegevens wanneer deze van toepassing
      zijn, zoals de gegevens van school, de huisarts en betrokken hulpverlening.
    </p>

    <h3>Doel verwerking persoonsgegevens</h3>
    <p>
      Spring Talentbegeleiding verwerkt persoonsgegevens om contact met u op te kunnen nemen en om het
      begeleidingstraject te kunnen ondersteunen. Indien van toepassing en uitsluitend met uw toestemming worden
      persoonsgegevens met derden gedeeld. U wordt hier altijd voorafgaand van op de hoogte gesteld. Dit geldt ook bij
      het inzetten van een samenwerkingspartner in uw hulpverleningstraject.
    </p>

    <h3>Rapportage</h3>
    <p>
      Spring Talentbegeleiding legt voor iedere cliënt een dossier aan waarin rapportage en verslaglegging worden
      verwerkt en overige communicatie (verkregen rapportages van derden, e-mailverkeer) wordt vastgelegd. Alles wat
      schriftelijk en/of digitaal is vastgelegd wordt enkel gedeeld met derden wanneer dat in het belang is van het
      hulpverleningstraject en wanneer u daar schriftelijk of per mail toestemming voor heeft gegeven. Daarnaast worden
      enkel gegevens gedeeld waarvan u voorafgaand inhoudelijk op de hoogte bent gesteld.
    </p>

    <h3>Beveiliging gegevens</h3>
    <p>
      Spring Talentbegeleiding gaat zorgvuldig met gegevens om en neemt passende maatregelen om misbruik, verlies,
      onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
    </p>
    <p>
      Alle informatie, rapportages en verslagen inzake uw hulpverleningstraject zijn vertrouwelijk en enkel voor u
      bestemd, tenzij u toestemming heeft gegeven deze met derden (school, huisarts, samenwerkingspartners en/of
      overige hulpverlening) te delen.
    </p>

    <h3>Bewaring gegevens</h3>
    <p>
      Spring Talentbegeleiding bewaart persoonsgegevens niet langer dan twee jaar. Bepaalde administratieve gegevens,
      zoals facturen en de overeenkomst met de opdrachtgever dienen door Spring Talentbegeleiding zeven jaar bewaard te
      worden in verband met de wettelijke termijn die op grond van fiscale wetgeving daarvoor geldt. Conform het
      privacyreglement heeft de cliënt of diens wettelijke vertegenwoordiger(s) recht op inzage van het dossier.
    </p>

    <h3>Gegevens inzien, aanpassen of verwijderen</h3>
    <p>
      U heeft recht om persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om
      eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw
      persoonsgegevens door Spring Talentbegeleiding.
    </p>
    <p>
      Ook heeft u het recht op gegevensoverdraagbaarheid, wat wil zeggen dat u bij ons een verzoek kunt indienen om uw
      persoonsgegevens waarover wij beschikken in een computerbestand naar u of een andere, door u genoemde, organisatie
      te sturen.
    </p>
    <p>
      U kunt een verzoek tot inzage, correctie, verwijdering en gegevensoverdraging van uw persoonsgegevens of verzoek
      tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar
      <a href="mailto:cilleke@springtalentbegeleiding.nl">cilleke@springtalentbegeleiding.nl</a>.
    </p>
    <p>
      Om gehoor te kunnen geven aan voornoemde verzoeken, kan er gevraagd worden naar legitimatie. Spring
      Talentbegeleiding wijst u er tevens op dat u de mogelijkheid heeft om een klacht in te dienen bij de
      nationale toezichthouder, de Autoriteit Persoonsgegevens.
    </p>
    <p>
      Indien u vragen heeft over het privacybeleid of de manier waarop uw persoonsgegevens verwerkt worden kunt u
      contact opnemen met de functionaris gegevensbescherming, voor Spring Talentbegeleiding ben ik dat zelf. Stuurt
      u in dat geval een mail naar
      <a href="mailto:cilleke@springtalentbegeleiding.nl">cilleke@springtalentbegeleiding.nl</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyContent"
}
</script>

<style scoped>
  #privacy {
    max-width:1000px;
    margin: 100px auto;
  }
</style>