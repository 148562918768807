<template>
  <div class="info cheetah">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 id="hoogbegaafdheid">Hoogbegaafdheid</h2>
        </b-col>
        <b-col xs="12" lg="6">
          <p>
            (Hoog)begaafde kinderen kunnen voor hun omgeving een flinke uitdaging zijn. Meestal is er naast het
            cognitieve talent ook sprake van een hogere gevoeligheid op diverse gebieden, een hoog bewustzijn en niet
            zelden een sterke wil. Maar ook zien we kinderen die zich te veel gaan aanpassen op een manier die hun
            ontwikkeling uiteindelijk belemmert. Als hoogbegaafdheid niet herkend en begrepen wordt ontstaat er
            makkelijk een mismatch tussen wat de omgeving van het kind vraagt en wat een kind nodig heeft om zich
            optimaal te kunnen ontwikkelen.
          </p>
          <p>
            Dit voelen de meesten van hen haarscherp aan en dat kan leiden tot gevoelens van onmacht, onkunde,
            frustratie en hulpeloosheid. Ook de omgeving kan gefrustreerd raken omdat al hun pogingen en inspanningen
            niet aanslaan. Spring Talentbegeleiding werkt graag preventief. Door het delen van kennis met ouders en
            professionals, het geven van psycho-educatie, observaties en gesprekken, plus ook door het faciliteren van
            peercontact en het kunnen ontwikkelen op eigen niveau in groepsaanbod.
          </p>
        </b-col>
        <b-col xs="12" lg="6">
          <b-img
              style="padding-top:2px;"
              :src="require('../../assets/iStock-1305978071.jpg')"
              fluid
          ></b-img>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: "Specifics"
}
</script>

<style>
</style>

