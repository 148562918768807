<template>
  <div>
    <div class="info accent">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 id="over">Over mij</h2>
          </b-col>
          <b-col xs="12" lg="5">
            <b-img
                class="about-picture"
              :src="require('@/assets/overmij.png')"
              fluid
            ></b-img>
          </b-col>
          <b-col xs="12" lg="6">
            <p>
              Ik ben Cilleke van Gijzen-Markslag, getrouwd en moeder van twee volwassen zoons. Beide zoons lieten al
              heel jong een voorsprong zien die ik destijds niet zo bijzonder vond, ik wist niet beter. Toen de oudste
              een klas oversloeg drukte ik hem vooral op het hart om daar niet te veel over te praten. Het aanpassen zat
              er ook bij mij goed in… Door mijn kinderen kwam de term hoogbegaafdheid op mijn pad, al zijn ze beiden nooit
              echt zo getest of benoemd. Wel waren er regelmatig tegenstrijdigheden: van zeer makkelijk lerend naar een
              trage verwerkingssnelheid, concentratie- en motivatieproblemen, een ontwikkeling die op sommige gebieden
              heel snel ging terwijl ze op andere gebieden 'gewoon' hun emotionele leeftijd waren.
            </p>
            <p>
              Dit zorgde voor vragen waar ik nergens echt antwoord op vond en voor veel onnodige onzekerheid, ook op
              het schoolse stuk en bij het leren leren. We hebben wat afgepuzzeld als ouders en samen met school.
              Uiteindelijk hebben ze allebei een middelbare school gevonden waar ze vanzelf met gelijkgestemden in
              aanraking kwamen en dat is op heel veel manieren heel goed geweest.
            </p>
            <p>
              Professioneel gezien heb ik een achtergrond en jarenlange werkervaring als jeugd- en gezinshulpverlener.
              Ik heb met alle leeftijden gewerkt en was betrokken bij zeer uiteenlopende ontwikkelingsvraagstukken bij
              kinderen. Vanwege mijn persoonlijke affiniteit met het onderwerp ben ik mij vanaf 2015 gaan specialiseren
              op het gebied van hoogbegaafdheid. Binnen mijn opleiding tot hoogbegaafdheidsspecialist en -begeleider heb
              ik me bekwaamd op het gebied van 'dubbel bijzonder', ook wel de 'twice exceptionals' genoemd. Door het
              volgen van trainingen, webinars en het bijwonen van congressen blijf ik me bijscholen en ontwikkelen.
              Daarnaast heb ik een groot netwerk van medeprofessionals waarin ik navraag kan doen en waar ik desgevraagd
              naar kan doorverwijzen.
            </p>
            <p>
              Tot slot: Tijdens mijn opleiding tot talentbegeleider vielen er destijds heel veel puzzelstukjes alsnog
              op hun plek. Hoogbegaafdheid bleek zich op veel manieren te kunnen manifesteren. Ik had deze kennis
              achteraf bekeken heel graag eerder willen hebben. Met daarbij meer en vooral passender adviezen over hoe
              je als omgeving zo goed mogelijk aan kunt sluiten bij de ontwikkeling van sneller lerende en zich
              ontwikkelende kinderen. Vanuit mijn eigen ervaring en kennis op dit gebied help ik nu heel graag anderen
              verder!
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
name: "About"
}
</script>


<style lang="scss" scoped>
  .about-picture {
    margin-bottom: 10px;
  }
</style>