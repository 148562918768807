.<template>
  <div>
    <Heading></Heading>
    <div class="info accent">
      <h2 class="head-text">&ldquo;UkIQ is er om kinderen op jonge leeftijd al te begeleiden bij een ontwikkelingsvoorsprong&rdquo;</h2>
    </div>

    <div class="info">
      <p class="leader-text">
        Denk jij dat jouw kind een ontwikkelingsvoorsprong heeft? Merk je bijvoorbeeld dat het leertempo van jouw kind
        veel hoger ligt dan dat van leeftijdsgenoten? Het is dan verstandig om contact op te nemen met ukIQ, zelf ben ik
        specialist voor de ukIQ-groep Hazerswoude-Rijndijk. We begeleiden hier kinderen al op jonge leeftijd bij een
        ontwikkelingsvoorsprong. Door deelname aan een ukIQ groep komen ze in contact met andere kinderen die ook een
        ontwikkelingsvoorsprong hebben.
      </p>

      <img class="img-ukiq" :src="require('../../assets/ukiq.png')">
    </div>

    <div class="info accent">
      <b-container>
        <b-row>
          <b-col lg="6" cols="12">
            <h2 id="dagdeel">Hoe ziet een dagdeel bij een ukIQ groep eruit?</h2>
            <p>
              De dagen op de ukIQ groep zien er ongeveer als volgt uit: de dag begint rustig, er is uitgebreid de tijd om
              afscheid te nemen van je kind. Daarna heeft de specialist –bij ukIQ-Hazerswoude-Rijndijk zal ik dat zijn–
              een programma voorbereid met een specifiek onderwerp, aangevuld met interactieve filmpjes, liedjes of andere
              materialen. Vervolgens gaan de kinderen zelf aan de slag. Er is ook altijd een beweegmoment op de dag. Soms
              is dit buiten, maar soms ook binnen of in de gymzaal. Tijdens de pauze eet iedereen zijn of haar
              tussendoortje en praten we over het onderwerp van de dag. Aan het eind van de ochtend vertellen de kinderen
              wat ze gedaan of beleefd hebben.
            </p>
            <p>
              Bij ukIQ ben ik te bereiken via mijn eigen mail adres: <a href="mailto:cilleke@ukiq.nl">cilleke@ukiq.nl</a>.
            </p>
            <h2>De drie pijlers van ukIQ</h2>
            <p>
              Binnen ukIQ worden drie hoofdpijlers gebruikt om de kinderen datgene te kunnen bieden waar zij behoefte aan
              hebben:
            </p>
            <strong>Peergroep</strong>
            <p>
              Oftewel gelijkgestemden, zo hoeven de kinderen zich niet ‘anders’ of buitengesloten te voelen. Door contact
              met andere kinderen met een ontwikkelingsvoorsprong kunnen zij zelf ook groeien.
            </p>
            <strong>Mindset</strong>
            <p>
              Door een juiste mindset kunnen obstakels beter worden overwonnen, de ontwikkeling van een goede mindset is
              dus heel belangrijk. UkIQ besteed hier veel aandacht aan.
            </p>
            <strong>Uitdaging</strong>
            <p>
              UkIQ biedt jonge kinderen volop uitdaging. Hierdoor blijft een gezonde nieuwsgierigheid gevoed en dit is
              goed voor de ontwikkeling van het kind.
            </p>
          </b-col>
          <b-col lg="6" cols="12">
            <img class="uk-img" alt="" :src="require('../../assets/ukiq_right_upper.png')">
            <img class="uk-img" alt="" :src="require('../../assets/ukiq_right_lower.jpeg')">
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Ukiq/Heading";

export default {
  name: "Ukiq",
  components: {
    Heading
  }
}
</script>

<style lang="scss" scoped>
  $leader-padding: 100px;

  .head-text {
    text-align: center;
  }

  @media screen and (min-width: 800px) {
    .leader-text {
      padding-left: $leader-padding;
      padding-right: $leader-padding;
    }
  }
  .leader-text {
    text-align: center;
  }

  .img-ukiq {
    display:block;
    margin: 0 auto;
    max-width: 240px;
  }

  .uk-img {
    width:150%;
  }
</style>