<template>
  <div>
    <b-img :src="require('../../assets/ukiq-header-1500x430.jpg')" id="ukiq-header" class="img-fluid w-100"></b-img>
  </div>
</template>

<script>
export default {
  name: "Heading"
}
</script>

<style scoped>
#ukiq-header {
  padding-top: 50px;
}
</style>