<template>
  <div>
    <b-navbar fixed="top" toggleable="lg" type="dark" variant="primary">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item>
            <router-link class="nav-link" to="/#home">Home</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#hoogbegaafdheid">Hoogbegaafdheid</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#hulp">Hulp vragen</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#aanbod">Aanbod</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#werkwijze">Werkwijze</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#tarieven">Tarieven</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#over">Over mij</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#recensies">Recensies</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/#contact">Contact</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/ukiq">UkIQ</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/positive-discipline">Positive Discipline</router-link>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto" right>
          <b-nav-item class="telefoon active" href="tel:+31648444392" right>Vrijblijvend een gesprek?<br>Bel 06 48 44 43 92</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      heads: [],
    }
  },
  methods: {
    scrollFix(hashbang) {
      location.hash = hashbang
    }
  },
  mounted() {
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  }
}
</script>

<style scoped>
  #navbar-img {
    max-width:100px;
  }

  .bg-primary {
    background-color: #4a4f99 !important;
  }

  .telefoon {
    right:0;
  }

  .ukiq {
    margin-top:40px;
    display: inline;
  }
</style>