<template>
  <div>
    <Heading></Heading>
    <div class="info accent s404">
      <h1>Deze pagina bestaat niet</h1>
      <div>
        Je hebt een pagina opgevraagd die niet bestaat, klik <router-link to="/">hier</router-link> om terug te gaan
        naar de home pagina!
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "../components/Main/Heading";
export default {
  name: "NotFound",
  components: {Heading}
}
</script>

<style lang="scss" scoped>
  .s404 {
    text-align: center;
    width: 100%;

    h1 {
      font-size: 60px !important;
    }
  }
</style>