<template>
  <div class="info accent">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 id="contact">Contact</h2>
        </b-col>
        <b-col cols="3"></b-col>
        <b-col cols="6">
          <b-form action="/contact.php" method="POST">
            <b-form-group
                id="input-group-1"
                label="E-mail adres:"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  type="email"
                  name="email"
                  placeholder="E-mail adres"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Naam:" label-for="input-2">
              <b-form-input
                  id="input-2"
                  placeholder="Naam"
                  name="name"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Bericht:" id="input-group-4">
              <b-form-textarea
                placeholder="Bericht"
                name="message"
              ></b-form-textarea>
            </b-form-group>

            <b-button class="spring-button" type="submit">Verstuur</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      form: {
        email: '',
        name: '',
        message: '',
      },
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      fetch('/contact.php', {
        method: 'POST',
        headers: {'Content-Type': 'Application/json'}
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.message = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

