<template>
  <div class="info">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 id="aanbod">Aanbod</h2>
        </b-col>
        <b-col cols="12">
          <p>
            Met mijn aanbod richt ik mij op ouders, kinderen, jongeren, pedagogisch medewerkers, direct betrokken
            opvoeders (zoals grootouders), leerkrachten en andere professionals die met kinderen en jeugd werken.
          </p>
          <p>
            Het aanbod van Spring Talentbegeleiding bestaat onder andere uit:
          </p>
        </b-col>
        <b-col xs="12" lg="8">
          <ul>
            <li>Individuele coaching aan kinderen en jongeren</li>
            <li>Opvoedcoaching</li>
            <li>Oudertraining verbindend opvoeden met de methode Positive Discipline</li>
            <li>Groepsaanbod voor peuters met een (vermoedelijke) ontwikkelingsvoorsprong</li>
            <li>Observaties op locatie (zoals thuis, op school, kinderdagverblijf of peuterspeelzaal)</li>
            <li>Aansluiten bij gesprekken op school, kinderdagverblijf of peuterspeelzaal</li>
            <li>Begeleiding op locatie</li>
            <li>Presentaties voor ouders en voor professionals uit (jeugd)zorg en onderwijs</li>
            <li>Consulterende gesprekken met ouders of professionals</li>
          </ul>
        </b-col>
        <b-col cols="12">
          <p>
            Daarnaast ben ik specialist op het gebied van ontwikkelingsvoorsprong bij het jonge kind (0-4 jaar). Ik kan
            door middel van gesprekken, observaties thuis en op kinderdagverblijven ondersteuning bieden bij het
            signaleren en begeleiden van peuters met een mogelijke ontwikkelingsvoorsprong.

            Als licentiehouder bij ukIQ verzorg ik wekelijks groepsaanbod voor peuters die meer uitdaging nodig hebben.
          </p>
          <b-button @click="ukiq()" id="btn-ukiq" class="spring-button">Ga naar ukIQ</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Aanbod",
  methods: {
    ukiq() {
      this.$router.push({path: '/ukiq'})
    }
  }
}
</script>

<style scoped>
  #btn-ukiq {
    right: 0;
  }

  li::marker{
    /*content:"\25A0";*/
    font-size: 1.8rem;
    color: rgba(167, 197, 7, 0.57);
    vertical-align: bottom;
  }

  li {
    padding-left:5px;
  }
</style>