<template>
  <div id="toc">
    <b-row>
      <b-col>
        <h2>Algemene voorwaarden</h2>

        <h3>Algemeen</h3>
        <p>
          Spring Talentbegeleiding is opgericht door Cilleke van Gijzen-Markslag, is gevestigd te Koudekerk aan den Rijn en
          ingeschreven bij de Kamer van Koophandel onder nummer 68296770.
        </p>
        <p>
          Cilleke van Gijzen-Markslag staat ingeschreven als Jeugd- en gezinsprofessional bij Stichting Kwaliteitsregister
          Jeugd onder registratienummer 110021396.
        </p>

        <h3>Toepasselijkheid</h3>
        <p>
          Deze algemene voorwaarden zijn van toepassing op alle mondelinge en schriftelijke offertes en overeenkomsten
          met Spring Talentbegeleiding en daarmee ook alle verband houdende handelingen, zowel van voorbereidende als
          van uitvoerende aard. Op voorhand dient u kennis te nemen van deze Algemene Voorwaarden. Voor aanvang van
          inzet door Spring Talentbegeleiding zullen wij u wijzen op deze algemene voorwaarden en om uw akkoord vragen.
        </p>

        <h3>Vertrouwelijkheid</h3>
        <p>
          De coachings- en begeleidingsgesprekken zijn vertrouwelijk. Mochten er echter signalen zijn die de veiligheid
          van u en/of uw kind in gevaar brengen, dan is Spring Talentbegeleiding wettelijk verplicht dit te bespreken
          met derden. Dit wordt altijd eerst met u besproken.
        </p>
        <p>
          Wanneer Spring Talentbegeleiding besluit bepaalde inhoudelijke informatie van het kind, voortkomend uit de
          coachingsessies, te delen met de ouder/verzorger dan wordt dit altijd eerst besproken met het kind.
        </p>

        <h3>Toestemming beide gezaghebbende ouders</h3>
        <p>
          Een kind komt in aanmerking voor begeleiding wanneer beide gezaghebbende ouders voorafgaand (schriftelijk)
          toestemming hiervoor geven. Informatie aan derden (huisarts, gemeente en school) wordt uitsluitend met
          toestemming van gezaghebbende ouders gedeeld.
        </p>

        <h3>Aansprakelijkheid</h3>
        <p>
          Advies, coaching en begeleiding door Spring Talentbegeleiding is bedoeld als oplossings- en resultaatgericht,
          zonder daarmee de oplossing of het resultaat te garanderen. De cliënt zal zichzelf verder in zijn/haar eigen
          proces ontwikkelen en is te allen tijde zelf verantwoordelijk voor gemaakte keuzes, diens eigen gedrag en de
          consequenties hiervan, zowel gedurende de periode van de coachingsessies als de periode daarna.
        </p>

        <p>
          Spring Talentbegeleiding is derhalve niet aansprakelijk voor directe of indirecte schade of letsel,
          contractueel of niet contractueel voortvloeiende uit, of in verband met, de geboden diensten, tenzij sprake is
          van opzet of grove schuld aan de zijde van Spring Talentbegeleiding.
        </p>

        <p>
          De aansprakelijkheid van Spring Talentbegeleiding is te allen tijde beperkt tot maximaal het bedrag dat is
          betaald door de cliënt of diens ouder/verzorger aan Spring Talentbegeleiding in het kader van het afgesproken
          coachings- of begeleidingstraject.
        </p>

        <p>
          Voor lichamelijke- en psychische klachten, raadt Spring Talentbegeleiding aan altijd contact op te nemen met
          uw huisarts.
        </p>

        <h3>De uitvoering</h3>
        <p>
          U kunt contact opnemen met Spring Talentbegeleiding via de contactgegevens genoemd op de website.
          Bij begeleiding van kinderen dient een ouder (telefonisch) bereikbaar te zijn.
          Tijdens het intakegesprek worden hulpvraag en hulpaanbod afgestemd. Tijdens een tussentijds en/of eindgesprek
          worden de vorderingen besproken en wordt afgestemd wat er nog nodig is om de coaching en/of begeleiding
          succesvol te kunnen beëindigen. Indien van toepassing kan hierbij ook worden doorverwezen voor verder
          onderzoek of andere soort hulpverlening.
        </p>

        <h3>Tarieven</h3>
        <p>
          De tarieven staan op de website. Alle bedragen zijn exclusief btw.
        </p>
        <p>
          Tariefswijzigingen worden minimaal 1 maand van tevoren aangekondigd.
        </p>
        <p>
          Kort overleg, telefonisch of per mail, is bij de begeleiding of coaching inbegrepen.
        </p>
        <p>
          Indien er meer tijd nodig is (> dan een kwartier) wordt er in overleg een afspraak gemaakt voor een gesprek of
          wordt de extra tijd berekend per kwartier. De kosten zijn in dat geval 18,75 euro per kwartier.
        </p>

        <h3>Betalingsvoorwaarden</h3>
        <p>
          De cliënt/ouder/verzorger verplicht zich de betaling binnen 14 dagen na de factuurdatum over te maken naar de
          rekening van Spring Talentbegeleiding. Als er niet binnen deze termijn wordt betaald zal Spring
          Talentbegeleiding een betalingsherinnering sturen. Indien er niet binnen 14 dagen aan de betalingsplicht
          is voldaan, is Spring Talentbegeleiding gerechtigd, bij de tweede herinnering administratiekosten à €25,- in
          rekening te brengen. Wordt er hierna nog niet aan de betalingsplicht voldaan, dan is Spring Talentbegeleiding
          genoodzaakt de vorderingen aan u, uit handen te geven aan derden. De kosten die hieraan verbonden zijn komen,
          conform de wet, volledig voor uw rekening. Bij betalingsachterstand is Spring Talentbegeleiding gerechtigd
          verdere begeleiding op te schorten totdat de cliënt/ouders/verzorgers aan hun betalingsverplichting hebben
          voldaan. Bij betaling via PGB worden hierover vooraf schriftelijke afspraken gemaakt. Deze voorwaarden zijn
          niet van toepassing wanneer de begeleiding wordt geboden middels Zorg In Natura (via een beschikking van de
          gemeente).
        </p>

        <h3>Verhindering</h3>
        <p>
          Indien bij verhindering tenminste 24 uur van tevoren wordt afgezegd, wordt de gereserveerde tijd niet in
          rekening gebracht. Bij afmelden korter dan 24 uur, zal dit wel het geval zijn. Tijdig verzetten van een
          afspraak is mogelijk, dit kan telefonisch of via email.
        </p>

        <h3>Klachten</h3>
        <p>
          Klachten die ontstaan ten gevolge van de overeenkomst dienen twee maanden nadat zij zijn ontstaan kenbaar te
          worden gemaakt aan Spring Talentbegeleiding door middel van de klachtenregeling op de website.  Na deze twee
          maanden worden klachten niet meer in behandeling genomen.
        </p>

        <h3>Ontbindingsclausule</h3>
        <p>
          Indien één der partijen constateert dat de andere partij tekort schiet in nakoming van de overeenkomst heeft
          de constaterende partij de keuze deze overeenkomst met onmiddellijke ingang te ontbinden of anders er
          aanspraak op te maken dat de overeenkomst alsnog door de tekortschietende partij wordt nagekomen. Zulks
          onverminderd de constaterende partij zijn of haar rechten op schadevergoeding wegens de tekortkoming.
        </p>
        <p>
          Tot slot: Indien één of meerdere bepalingen uit deze algemene voorwaarden nietig of niet rechtsgeldig worden
          verklaard dan blijven de overige bepalingen van kracht.
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "TocContent"
}
</script>

<style scoped>
  #toc {
    max-width:1000px;
    margin: 100px auto;
  }
</style>