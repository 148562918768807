<template>
  <div>
    <Navbar></Navbar>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  name: 'App',
  components: {Navbar, Footer},
}
</script>

<style lang="scss">
@import "scss/variables";

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/Ubuntu-Regular.ttf') format('truetype');
}

html, body {
  font-family: "Ubuntu", sans-serif !important;
}

#app {
  font-family: "Ubuntu", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.info {
  padding: 50px 20px;
}

.accent {
  background-color: $accent-color;
}

h1 {
  font-size: 1.8rem !important;
}

h1, h2, h3 {
  color: #4a4f99;
  font-weight: bold !important;
}

// Dirty hack to align the automated scroll well
h2::before, h1::before {
  content: " ";
  margin-top: -120px;
  padding-top: 120px;
  visibility: hidden;
  pointer-events: none;
  display: block;
}

.spring-button {
  background-color: #4a4f99 !important;
}

</style>
