import Main from "@/pages/Main";
import Toc from "@/pages/Toc";
import Privacy from "@/pages/Privacy";
import NotFound from "@/pages/NotFound";
import Ukiq from "./components/Ukiq/Ukiq";
import Pdn from "./components/Pdn/Pdn";
import Klachtenregeling from "./pages/Klachtenregeling";

export let routes = [
    {path: '/', component: Main},
    {path: '/ukiq', component: Ukiq},
    {path: '/positive-discipline', component: Pdn},
    {path: '/klachten', component: Klachtenregeling},
    {path: '/voorwaarden', component: Toc},
    {path: '/privacy', component: Privacy},
    {path: '*', component: NotFound},
]