<template>
  <div>
    <klachtenregeling-content></klachtenregeling-content>
  </div>
</template>

<script>

import KlachtenregelingContent from "../components/Klachtenregeling/KlachtenregelingContent";

export default {
  name: "Klachtenregeling",
  components: {
    KlachtenregelingContent,
  }
}
</script>

<style scoped>

</style>