<template>
  <div>
    <div class="info">
      <carousel
          class="carousel"
          :per-page="1"
          :mouse-drag="true"
          :navigationEnabled="true"
          :autoplay="true"
          :autoplayTimeout="25000"
          :loop="true"
          :adjustableHeight="true"
      >
        <slide
            :data-index="index" :key="index" v-for="[index, testimonial] of testimonials.entries()">
          <h3>{{testimonial.caption}}</h3>
          <div class="carousel-text" v-html="testimonial.text"></div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: "Recensies",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      testimonials: [
        {
          caption: "Vader van 2 kinderen, 6 en 8 jaar",
          text: "“De tools vanuit Positive Discipline gaven mij weer richting tot een vrolijker en evenwichtig gezin! Aan het begin van de cursus zat ik soms echt met de handen in het haar. Ik heb weer vertrouwen gekregen in mijn kinderen en in mezelf als ouder.“"
        },
        {
          caption: "Moeder van 5 kinderen, 5, 6, 8, 8 en 10 jaar",
          text: "“Ik ben heel blij en dankbaar voor de vernieuwde band en connectie die ik met mijn (strong willed, hoogbegaafde en gevoelige) dochter (8) heb door de veranderde manier van omgaan met elkaar, na het volgen van de oudercursus bij Cilleke.“",
        },
        {
          caption: "Vader van 3 kinderen, 4, 6 en 8 jaar",
          text: "“Cilleke zet je aan het denken over je eigen rol als ouder en de invloed op het gedrag van je kind.“"
        },
        {
          caption: "Vader van 2 kinderen, 4 en 2 jaar",
          text: "“Een cursus over het (anders dan conventioneel) opvoeden van kinderen, waarin ik ook mezelf beter heb leren kennen.”"
        },
        {
          caption: "Vader van een 3-jarige met ontwikkelingsvoorsprong",
          text: "“Een nuttige én leuke cursus voor ouders die worstelen met eigenwijze kinderen.“"
        },
      ]
    }
  },
  mounted() {
    setInterval(() => {

    }, 25000)
  }
}
</script>


<style lang="scss" scoped>
  #recensies {
    width: 100%;
    text-align: center;
  }

  .carousel-text {
    font-style: italic;
    padding: 5px;
  }

  .carousel {
    margin: 0 auto;
    text-align: center;
  }

  @media screen and (min-width: 992px) {
    .carousel {
      width:80%;
    }
  }

  h3 {
    color: black;
  }
</style>