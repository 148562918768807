import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import BootstrapVue, {IconsPlugin} from "bootstrap-vue";
import {routes} from "@/routes";

import '@/scss/main.scss'

Vue.config.productionTip = false

const router = new VueRouter({
    routes: routes,
    mode: 'history',
    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        } else {
            return {x: 0, y: 0}
        }
    }
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
