<template>
  <div>
    <TocContent></TocContent>
  </div>
</template>

<script>
import TocContent from "@/components/Toc/TocContent";
export default {
  name: "Toc",
  components: {TocContent}
}
</script>

<style scoped>
</style>