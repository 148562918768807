.<template>
  <div>
    <b-img
        class="img-header"
        :src="require('@/assets/SpringTalentbegeleiding_Logo_rgb.png')"
        fluid-grow
    ></b-img>
    <div class="info accent">
      <h2 class="head-text">&ldquo;Kinderen <span class="head-accent">DOEN</span> het beter als ze zich beter <span class="head-accent">VOELEN</span>&rdquo;</h2>
    </div>

    <div class="info">
      <p class="leader-text">
        Positive Discipline is een internationaal programma dat kinderen en jonge mensen stimuleert om
        verantwoordelijke, respectvolle en vindingrijke leden van de samenleving te worden. Zonder straffen en zonder
        toegeven: maar vriendelijk én vastberaden.
      </p>

      <img class="img-ukiq" :src="require('@/assets/logo Positive Discipline.png')">
    </div>

    <div class="info accent">
      <b-container>
        <b-row>
          <b-col lg="8" cols="12">
            <h2 id="dagdeel">Positive Discipline: zo doe je dat!</h2>
            <li class="point">Wees vriendelijk en vastberaden tegelijk</li>
            <p class="subtext">Vriendelijk als uiting van respect naar je kind; vastberaden tegenover jezelf en de situatie.</p>

            <li class="point">Laat voelen dat je kind er toe doet</li>
            <p class="subtext">Erbij horen is de basisbehoefte van alle mensen, en zeker van kinderen.</p>

            <li class="point">Richt je op de lange termijn</li>
            <p class="subtext">Straf is voor de korte termijn. Wat denkt, voelt en besluit je kind, en hoe zullen deze
              overtuigingen zijn verdere leven beïnvloeden?</p>

            <li class="point">Help je kind vaardigheden te ontwikkelen</li>
            <p class="subtext">Een goed karakter ontwikkelt zich van binnen uit. Help een kind vaardigheden te
              ontwikkelen als problemen oplossen, helder denken, samenwerken, vriendelijk communiceren,
              verantwoordelijkheid, zelfdiscipline.</p>

            <li class="point">Help kinderen te ontdekken hoe capabel ze zijn!</li>
            <p class="subtext">Hoe ze hun persoonlijke invloed op een nuttige manier kunnen gebruiken en daarmee een
              bijdrage leveren aan hun omgeving.</p>
          </b-col>
          <b-col cols="12" lg="4">
            <img class="img-fluid img-ijsberg" alt="ijsberg" :src="require('@/assets/ijsberg.png')">
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="info">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 id="recensies">Reacties van ouders</h2>
            <p id="undertitle">Na het volgen van de cursus <strong>Positive Discipline</strong></p>
            <Recensies></Recensies>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Recensies from "./Recensies.vue";

export default {
  name: "Pdn",
  components: {Recensies}
}
</script>

<style lang="scss" scoped>
  $leader-padding: 100px;

  #undertitle {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: -0.7rem;
    color: #7a7a7a;
  }

  .img-ijsberg {
    max-width: 115%;
  }

  li {
    list-style-type: "✓";
  }

  .point {
    font-size: 1.5rem;
    color: #4a4f99;
  }

  .img-header {
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("../../assets/dunes.jpg");
    background-size: cover;
  }

  .head-text {
    color: white;
    text-align: center;
  }

  .head-accent {
    color: #4a4f99;
  }

  @media screen and (min-width: 800px) {
    .leader-text {
      padding-left: $leader-padding;
      padding-right: $leader-padding;
    }
  }
  .leader-text {
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
  }

  .img-ukiq {
    display:block;
    margin: 0 auto;
    max-width: 240px;
  }

  .uk-img {
    width:150%;
  }
</style>