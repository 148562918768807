<template>
  <div>
    <Heading></Heading>
    <Information></Information>
    <Hoogbegaafdheid></Hoogbegaafdheid>
    <Recognition></Recognition>
    <Aanbod></Aanbod>
    <Werkwijze></Werkwijze>
    <Cost></Cost>
    <About></About>
    <Recensies></Recensies>
    <Contact></Contact>
  </div>
</template>

<script>
import Heading from "@/components/Main/Heading";
import Information from "@/components/Main/Information";
import Hoogbegaafdheid from "@/components/Main/Hoogbegaafdheid";
import Recognition from "@/components/Main/Recognition";
import Cost from "@/components/Main/Cost";
import About from "@/components/Main/About";
import Contact from "@/components/Main/Contact";
import Aanbod from "@/components/Main/Aanbod";
import Werkwijze from "@/components/Main/Werkwijze";
import Recensies from "@/components/Main/Recensies";

export default {
  name: 'MainPage',
  components: {
    About,
    Contact,
    Recognition,
    Hoogbegaafdheid,
    Aanbod,
    Werkwijze,
    Information,
    Cost,
    Recensies,
    Heading,
  }
}
</script>

<style lang="scss">
@import "../scss/variables";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.info {
  padding: 50px 20px;
}

.accent {
  background-color: $accent-color;
}

// Dirty hack to align the automated scroll well
h2::before {
  content: " ";
  margin-top: -120px;
  padding-top: 120px;
  visibility: hidden;
  pointer-events: none;
  display: block;
}
</style>
