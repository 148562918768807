<template>
  <div class="footer">
    <b-container>
      <b-row>
        <b-col lg="4" cols="12">
          <b-img
            :src="require('@/assets/SpringTalentbegeleiding_Logo_rgb.png')"
            fluid
          ></b-img>
        </b-col>
        <b-col cols="12" lg="2"></b-col>
        <b-col>
          <ul class="link-list">
            <li><router-link to="/voorwaarden">Algemene Voorwaarden</router-link></li>
            <li><router-link to="/privacy">Privacyverklaring</router-link></li>
            <li><router-link to="/klachten">Klachtenregeling</router-link></li>
          </ul>

          <ul>
            <li>E-mail: <a href="mailto:info@springtalentbegeleiding.nl">info@springtalentbegeleiding.nl</a></li>
            <li>Telefoon: <a href="tel:+31648444392">06-48444392</a></li>
            <li>KvK: 68296770</li>
          </ul>
        </b-col>
      </b-row>
      <b-row style="margin-bottom:20px;">
        <b-col cols="6" lg="2">
          <b-img
              :src="require('@/assets/SKJ_logo-e1525093474269.png')"
              fluid
          ></b-img>
        </b-col>
        <b-col cols="6" lg="2">
          <b-img
              id="ukiq"
              :src="require('@/assets/ukiq.png')"
              fluid
          ></b-img>
        </b-col>
        <b-col cols="6" lg="2">
          <b-img
              id="pdn"
              :src="require('@/assets/logo Positive Discipline.png')"
              fluid
          ></b-img>
        </b-col>
      </b-row>

    </b-container>
    <div class="brand-container">
      <div class="green"></div>
      <div class="space"></div>
      <div class="purple"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style lang="scss" scoped>
  .footer {
    min-height:200px;
    background-color:whitesmoke;
  }

  #ukiq {
    padding-top:24px;
    width:60%;
  }

  .link-list {
    padding-top: 40px;
  }

  .brand-container {
    height: 30px;
    width:100%;

    .green {
      background-color: #a7c509;
      height:100%;
      width:69%;
      display: inline-block;
    }

    .space {
      display: inline-block;
      width: 1%;
    }

    .purple {
      height:100%;
      width:30%;
      background-color: #4a4f99;
      display: inline-block;
    }
  }
</style>
