<template>
  <div id="klachten">
    <b-row>
      <b-col>
        <h2>Klachtenregeling</h2>
        <p>
          Spring Talentbegeleiding streeft ernaar om haar zorg naar volledige tevredenheid van haar cliënten uit te
          voeren. Mocht het toch voorkomen dat u niet tevreden bent over de geleverde zorg of heeft u een andere
          klacht, dan verneem ik dat graag van u. Bij klachten ga ik er vanuit dat u deze altijd eerst kenbaar maakt
          en bespreekt met mij persoonlijk. U kunt uw klacht in dat geval mailen naar
          <a href="mailto:cilleke@springtalentbegeleiding.nl">cilleke@springtalentbegeleiding.nl</a>.
          Ik zal daar binnen tien werkdagen op reageren, uitgezonderd ziekte of vakantie. Ik zal me dan inzetten om
          samen tot een passende oplossing te komen binnen de mogelijkheden van beide kanten.
        </p>

        <p>
          Inmiddels is Spring Talentbegeleiding aangesloten bij SoloPartners voor een erkende en goedgekeurde
          klachtenregeling. Mocht er een klacht zijn waar we op de informele manier zoals omschreven niet uitkomen dan
          kunt u contact opnemen met een onafhankelijke klachtenfunctionaris. U kunt hiervoor mailen naar:
          <a href="mailto:info@solopartners.nl">info@solopartners.nl</a>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "KlachtenregelingContent"
}
</script>

<style scoped>
#klachten {
  max-width:1000px;
  margin: 100px auto;
}
</style>
