<template>
  <div class="info accent">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h2 id="hulp">Wanneer schakel je mij in?</h2>
        </b-col>
        <b-col xs="12" lg="6">
          <p>
            Bij signalen van een ontwikkelingsvoorsprong, (vermoedens van) hoogbegaafdheid, hoogsensitiviteit,
            problemen in het omgaan met prikkels of prikkelgevoeligheid in het gezin, alle hulpvragen op het gebied van
            sociale en emotionele ontwikkeling, motivatieproblemen, opvoedvragen en hulpvragen bij (vermoedelijke) ADD.
          </p>
        </b-col>
        <b-col xs="12" lg="6">
          <p>
            Twijfel je of ik iets voor jou of je kind(eren) kan betekenen, neem dan gerust contact met me op!
            Telefoonnummer <a href="tel:+31648444392">06-48444392</a>.
          </p>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: "Specifics"
}
</script>
